import React, { useState } from "react";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./index.css";
import {
  Layout,
  Button,
  Avatar,
  Popover,
  Typography,
  Divider,
  Menu,
} from "antd";
import { useAuth } from "../../../../../context/auth-context";
import useWindowDimensions from "../../../UtilComponents/useWindowDimensions";
import { MdPerson } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
const Topbar = ({ colorBgContainer, setCollapsed, collapsed }) => {
  const authValue = useAuth();
  const navigate = useNavigate();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { width } = useWindowDimensions();

  const menu = [
    {
      label: (
        <Button
          type="text"
          className="p-0 logout-buttton-style text-left"
          style={{ width: "100%" }}
          icon={<LogoutOutlined />}
          onClick={authValue?.logoutHandler}
        >
          Logout
        </Button>
      ),
      key: "1",
    },
  ];

  const content = (
    <div className="d-flex flex-column " style={{ width: 200 }}>
      <div className="d-flex align-items-center flex-column p-2 py-3">
        <Avatar
          shape="square"
          size={45}
          icon={
            <MdPerson
              style={{
                height: 35,
                width: 35,
              }}
            />
          }
        />
        <Typography className="mt-2 name-text">
          {authValue?.currUser?.name}
        </Typography>
        <Typography className="role-text">
          Role:- {authValue?.currUser?.role_details?.name}
        </Typography>
        <Typography className="username-text">
          @{authValue?.currUser?.user_name}
        </Typography>
        {!["/profile", "/profile/"].includes(window.location.pathname) ? (
          <Button
            size="small"
            type="primary"
            className="mt-1"
            onClick={() => {
              setPopoverVisible(false);
              navigate("/profile");
            }}
          >
            View Profile
          </Button>
        ) : null}
      </div>
      <Divider />
      <div className="py-1">
        <Menu
          style={{ width: "100%" }}
          mode="inline"
          items={menu}
          className="profile-menu"
        />
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Header
        className={`d-flex justify-content-between align-items-center ${
          width >= 768
            ? collapsed
              ? "header-wrapper-collapsed"
              : "header-wrapper"
            : ""
        } header-style`}
        style={{
          background: colorBgContainer,
          ...(width >= 768
            ? {
                paddingLeft: collapsed ? 65 : 220,
              }
            : { paddingLeft: 0 }),
        }}
      >
        <Button
          type="text"
          style={{
            fontSize: 16,
            width: 54,
            height: 54,
            border: "none",
            outline: "none",
          }}
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <Popover
          content={content}
          trigger="click"
          open={popoverVisible}
          onOpenChange={() => {
            setPopoverVisible(!popoverVisible);
          }}
          overlayClassName="avatar-popover"
        >
          <Avatar
            size={30}
            shape="square"
            className="avatar-style"
            onClick={() => {
              setPopoverVisible(!popoverVisible);
            }}
            icon={<UserOutlined size={50} />}
          />
        </Popover>
      </Header>
    </React.Fragment>
  );
};

export default Topbar;
