import {
  TbDatabase,
  TbLayoutDashboard,
  TbUser,
  TbUserSquare,
} from "react-icons/tb";
import { RiOpenSourceLine } from "react-icons/ri";
import { PiCalendarDotsDuotone, PiFunnel } from "react-icons/pi";
import { SiStagetimer } from "react-icons/si";
import { CgCap } from "react-icons/cg";
import { lazy } from "react";
import { GrStatusPlaceholder, GrConfigure } from "react-icons/gr";
import { HiOutlineLocationMarker } from "react-icons/hi";

const modules = [
  {
    key: "1",
    icon: <TbLayoutDashboard />,
    label: "Dashboard",
    route: "/",
    permission: {
      is_view: [1, 2, 3, 4, 5],
    },
    component: lazy(() => import("../page/Dashboard")),
    is_route: true,
    is_sidebar: true,
  },
  {
    key: "2",
    icon: <TbDatabase />,
    label: "Master Data",
    permission: {
      is_view: [1, 2],
    },
    is_route: true,
    is_sidebar: true,
    children: [
      // {
      //   key: "6",
      //   icon: <PiCalendarDotsDuotone />,
      //   label: "Academic Year",
      //   route: "/master-data/academic-year",
      //   permission: {
      //     is_view: [1, 2],
      //     is_add: [1],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/AcademicYear")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      // {
      //   key: "7",
      //   icon: <PiFunnel />,
      //   label: "Funnel Stage",
      //   route: "/master-data/funnel-stage",
      //   permission: {
      //     is_view: [1, 2],
      //     is_add: [1],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/FunnelStage")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      // {
      //   key: "9",
      //   icon: <GrStatusPlaceholder />,
      //   label: "Enquiry Status",
      //   route: "/master-data/enquiry-status",
      //   permission: {
      //     is_view: [1, 2],
      //     is_add: [1],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/EnquiryStatus")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      // {
      //   key: "14",
      //   icon: <GrConfigure />,
      //   label: "Inside Sales Config",
      //   route: "/master-data/inside-sales-config",
      //   permission: {
      //     is_view: [1, 2],
      //     is_add: [1],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/InsideSalesConfig")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      {
        key: "3",
        icon: <CgCap />,
        label: "Role",
        route: "/master-data/role",
        permission: {
          is_view: [1, 2],
        },
        component: lazy(() => import("../page/Role")),
        is_route: true,
        is_sidebar: true,
      },
      {
        key: "13",
        route: "/master-data/role/:id",
        permission: {
          is_view: [1, 2],
        },
        component: lazy(() => import("../page/RoleWiseModule")),
        is_route: true,
        is_sidebar: false,
      },
      // {
      //   key: "4",
      //   icon: <RiOpenSourceLine />,
      //   label: "Lead Source",
      //   route: "/master-data/lead-source",
      //   permission: {
      //     is_view: [1, 2],
      //     is_add: [1],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/LeadSource")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      // {
      //   key: "5",
      //   icon: <SiStagetimer />,
      //   label: "Lead Stage",
      //   route: "/master-data/lead-stage",
      //   permission: {
      //     is_view: [1, 2],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/LeadStage")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      // {
      //   key: "10",
      //   icon: <HiOutlineLocationMarker />,
      //   label: "Location",
      //   route: "/master-data/location",
      //   permission: {
      //     is_view: [1, 2],
      //   },
      //   component: lazy(() => import("../page/Location")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
      // {
      //   key: "8",
      //   icon: <PiFunnel />,
      //   label: "School Board",
      //   route: "/master-data/school-board",
      //   permission: {
      //     is_view: [1, 2],
      //     is_add: [1],
      //     is_modify: [1],
      //   },
      //   component: lazy(() => import("../page/SchoolBoard")),
      //   is_route: true,
      //   is_sidebar: true,
      // },
    ],
  },
  {
    key: "11",
    icon: <TbUser />,
    label: "User Management",
    route: "/user-management",
    permission: {
      is_view: [1, 2, 3, 5],
      is_add: [1, 2, 3, 5],
      is_modify: [1, 2, 3, 5],
    },
    component: lazy(() => import("../page/UserManagement")),
    is_route: true,
    is_sidebar: true,
  },
  {
    key: "12",
    route: "/profile",
    permission: {
      is_view: [1, 2, 3, 4, 5],
    },
    component: lazy(() => import("../page/Profile")),
    is_route: true,
    is_sidebar: false,
  },
  // {
  //   key: "15",
  //   icon: <TbUserSquare />,
  //   label: "Lead Management",
  //   route: "/lead-management",
  //   permission: {
  //     is_view: [1, 2, 3, 4, 5],
  //     is_add: [1, 2, 3, 4, 5],
  //     is_modify: [1, 2, 3, 4, 5],
  //   },
  //   component: lazy(() => import("../page/LeadManagement")),
  //   is_route: true,
  //   is_sidebar: true,
  // },
];

export default modules;
